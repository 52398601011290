import React, { useEffect, useState } from "react";
import { LoginError } from "../components/TWComponents/LoginError";

import Layout from "../layouts/en";
import SEO from "../components/seo";

const LoginErrorPage = (props) => {
  const [description, setDescription] = useState(null);

  useEffect(() => {
    // Access window only on the client side
    if (typeof window !== "undefined") {
      const queryParams = new URLSearchParams(window.location.search);
      setDescription(queryParams.get("error_description"));
    }
  }, []);

  return (
    <Layout location={props.location} showBookNowButton={false}>
      <SEO
        title="Login Failed"
        keyword="Login Failed"
        description="Login Failed"
      />
      <LoginError
        title="Login failed"
        description={description || "No error description available."}
        subDescription="We encountered an issue while processing your login request. Please try again or contact our team if this problem persists."
        showErrorDetails="Show error details"
        hideErrorDetails="Hide error details"
        needAssistance="Need any assistance?"
        contactSupport="Contact support"
      />
    </Layout>
  );
};

export default LoginErrorPage;
